// import { createContext, useContext, useState, useEffect } from "react";

// // create Context
// const ThemeContext = createContext();

// //create theme provider 
// // it is wrapper component provide theme related data to its childrens
// // why use children? => it is special prop in react. this ensures all other component can use theme context
// export const ThemeProvider = ({children})=>{

// // load theme from localStorage or default to "light" theme

// const [theme, setTheme]= useState(localStorage.getItem("theme") || "light");


// //toggle theme function
// const toggleTheme =()=>{
//     const newTheme= theme === 'light' ? 'dark' : 'light';
//     setTheme(newTheme);
//     localStorage.setItem("theme", newTheme);
// }

// //update theme in localStorage and in HTML when it changes 
// // we are using useEffectv to store 
// useEffect(()=>{
//     document.body.className =theme;
// },[theme]);

// return (
//     <ThemeContext.Provider value={{ theme, toggleTheme }}>
//       {children}
//     </ThemeContext.Provider>
//   );

// };


// // custom hook for using theme context
// export const useTheme = () => useContext(ThemeContext);















import { createContext, useContext, useState, useEffect } from "react";

// Create Context
const ThemeContext = createContext();

// Theme Provider
export const ThemeProvider = ({ children }) => {
  // Initially set theme to `null` to prevent flicker
  const [theme, setTheme] = useState(null);

  useEffect(() => {
    const storedTheme = localStorage.getItem("theme") || "light";
    setTheme(storedTheme);
    document.body.className = storedTheme;
  }, []);

  // Toggle theme function
  const toggleTheme = () => {
    const newTheme = theme === "light" ? "dark" : "light";
    setTheme(newTheme);
    localStorage.setItem("theme", newTheme);
    document.body.className = newTheme;
  };

  // Prevent rendering until theme is applied
  if (!theme) return <div style={{ backgroundColor: "#121212", height: "100vh" }} />;

  return <ThemeContext.Provider value={{ theme, toggleTheme }}>{children}</ThemeContext.Provider>;
};

// Custom hook for using theme context
export const useTheme = () => useContext(ThemeContext);
