import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
 import { ThemeProvider } from './context/ThemeContext';
 import posthog from 'posthog-js';


const root = ReactDOM.createRoot(document.getElementById("root"));




posthog.init(process.env.REACT_APP_PUBLIC_POSTHOG_KEY, {
  api_host: 'https://us.i.posthog.com',
  debug: true,
  loaded: (ph) => {
    // Assign to window when it's ready
    window.posthog = ph
    console.log('PostHog loaded and attached to window.')
  },
})



root.render(

  <ThemeProvider>
  <App />
</ThemeProvider>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
