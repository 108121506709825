import React from 'react'
import { useEffect } from 'react'

const AdSense = () => {
    useEffect(() => {
        // Load the AdSense script
        const script = document.createElement("script");
        script.src =
          "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-1908393047392654";
        script.async = true;
        script.crossOrigin = "anonymous";
        document.body.appendChild(script);
    
        // Push the ad
        try {
          (window.adsbygoogle = window.adsbygoogle || []).push({});
        } catch (e) {
          console.error("Adsense error", e);
        }
      }, []);
  return (
    <div style={{ maxWidth: "100%", overflow: "hidden" }}>
    <ins
    className="adsbygoogle"
    style={{ display: "block" }}
    data-ad-client="ca-pub-1908393047392654"
    data-ad-slot="8486634722"
    data-ad-format="auto"
    data-full-width-responsive="true"
  ></ins>
  </div>
  )
}

export default AdSense