import "./App.css";
import React from "react";
// import { Route, Switch } from "react-router-dom";
import HomePage from "./HomePage";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Blogs from "./Blogs";
import Changelog from "./Changelog";
import PrivacyPolicy from "./PrivacyPolicy";

import Recipes from "./Recipes";
import Header from "./Header";
import ViewRecipe from "./ViewRecipe";







const color  = "#00d66f";
const router = createBrowserRouter([
  {
    path: "/",
    element: <HomePage />,
  },
  {
    path: "/blogs",
    element: <Blogs />,
  },

  {
    path:"/recipe",
    element: <Recipes/>,
  },
  {
    path: "/mobile/changelog",
    element: <Changelog />,
  }
,
  {
    path: "/privacy-policy",
    element: <PrivacyPolicy />,
  },

  {
    path: "/view-recipe/:id",
    element: <ViewRecipe />,
  }

]);

function App() {


  
  

  return (
    <>
   <Header />
       <div className="App">
        <RouterProvider router={router} />
      </div>
    </>
  );
}

export default App;
