import React from "react";

import { FaShareAlt } from "react-icons/fa";
import "./blogs.css";
import { useTheme } from "./context/ThemeContext";
import Footer from "./Footer";


const Blogs = () => {
const {theme} =useTheme();
  const shareData = {
    title: "Healthy food and healthy you!",
    text: "Hey, folks! Life can be crazy busy, but don\’t let that get in the way of your health. You\’re missing out on some significant benefits if you\’re not watching what you’re eating. Here are some reasons why you should start monitoring your nutritional intake right now: 1. Get More Energy     Who doesn\'t want to feel more energized? If you\'re eating a balanced   diet with all the essential vitamins and minerals, you can power   through your day without feeling sluggish or tired.   2. Improve Your Digestion     Let\'s face it - no one likes to feel bloated or constipated. Keeping   an eye on what you\'re eating can help you avoid digestive issues and   keep your gut happy.  3. Lower Your Risk of Diseases     Nobody wants to get sick. Eating a diet rich in fruits, veggies,   whole grains, and lean proteins gives your body the tools it needs   to fight off chronic diseases like heart disease, diabetes, and   cancer.   4. Manage Your Weight    Trying to lose or maintain weight? Monitoring your nutritional   intake is a great way to keep your calorie intake in check and   ensure you\'re eating a balanced diet supporting your goals.  5. Boost Your Mood   What you eat affects how you feel. Eating a diet high in omega-3   fatty acids and B vitamins can help improve your mood and reduce the   risk of depression and other mental health disorders. So, what are you waiting for? Start paying attention to what you're eating and see how it can make a huge difference in your overall health and well-being. Snack on!",
    url: "https://snacko.health/blogs",
  };


  async function share(){
     await navigator.share(shareData);
  }
  return (
  
         {/* <CustomNavBar /> */},

<div className={`blogs-container ${theme}`}>


    <div className="blogs-sec">
      <div className="blog-heading">
        <h2>Nourishing Insights: Exploring the Benefits of Nutritious Foods</h2>
      </div>
      <div className="Name-sharebtn">
        <div className="name-description">
          <div>
            <i><span> - Team Snacko</span></i>
          </div>
          {/* <div>
            <span>Description of Person</span>
          </div> */}
        </div>
        <div className="share-icon" onClick={share}>
          <FaShareAlt /> <span>Share</span>
        </div>
      </div>
      <div className="Image">
        <span className="textinimg">{shareData.title}</span>
      </div>
      <div className="blog-text">
        <p>
          Hey, folks! Life can be crazy busy, but don’t let that get in the way
          of your health. You’re missing out on some significant benefits if
          you’re not watching what you’re eating. Here are some reasons why you
          should start monitoring your nutritional intake right now:
          <br /><br />
         <b> <p>1. Get More Energy</p></b>
          <p>
            
            Who doesn’t want to feel more energized? If you’re eating a balanced
            diet with all the essential vitamins and minerals, you can power
            through your day without feeling sluggish or tired.
          </p>
          <br />
   <b>       <p> 2. Improve Your Digestion </p></b>
          <p>
            Let’s face it - no one likes to feel bloated or constipated. Keeping
            an eye on what you’re eating can help you avoid digestive issues and
            keep your gut happy.
          </p><br />
        <b>  <p>3. Lower Your Risk of Diseases </p></b>
          <p>
            Nobody wants to get sick. Eating a diet rich in fruits, veggies,
            whole grains, and lean proteins gives your body the tools it needs
            to fight off chronic diseases like heart disease, diabetes, and
            cancer.
          </p>
          <br />
<b>          <p> 4. Manage Your Weight</p></b>
          <p>
        
            Trying to lose or maintain weight? Monitoring your nutritional
            intake is a great way to keep your calorie intake in check and
            ensure you’re eating a balanced diet supporting your goals.
          </p>
          <br />
      <b>    <p> 5. Boost Your Mood</p></b>
          <p>
          
            What you eat affects how you feel. Eating a diet high in omega-3
            fatty acids and B vitamins can help improve your mood and reduce the
            risk of depression and other mental health disorders.
          </p>
          So, what are you waiting for? Start paying attention to what you’re
          eating and see how it can make a huge difference in your overall
          health and well-being.
          
          <br /><br />
<i>
        Snack on! </i>
        </p>
      </div>
    </div>
    <Footer />
    </div>
  );
};

export default Blogs;
