import React from 'react'
import { useTheme } from './context/ThemeContext'
import AppIcon from "./images/app_icon.png"
import './footer.css'
import playstoreImg from "./images/play_store.svg"
import AppStoreimge from "./images/app_store.svg"

const Footer = () => {
    const {theme} = useTheme();

  return (
    // <div>
    //     <div className="footer">
    //     <div className="logo-div">
    //         <img src={AppIcon} style={{width:"10rem", height:"10rem"}} alt="" />
    //         <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Ipsum animi in, corporis necessitatibus nisi exercitationem minima! Illo, atque dolores repellat aspernatur mollitia culpa. Enim labore necessitatibus at deserunt vero iusto?</p>
    //     </div>
    //     <div className="quick-links">
    //         <span>Quick Links</span>
    //         <ul>
    //           <li><a href="">Home</a></li>
    //           <li><a href="">Changelog</a></li>
    //           <li><a href="">Privacy Policy</a></li>  
    //         </ul>
    //     </div>
    //     <div className="contact">
    //         <span>Feel free to contact us</span>
    //         <div className="contact">
    //             <ul>
    //         <li>Email:<a  href=""> sidkit@gmail.com</a></li>
    //         <li>Contact no:<a href=""> +17283838843</a></li>
    //         </ul>
    //         </div>
    //     </div>

    // </div>
    // </div>

<>
{/* <section>Footer Example 4</section> */}
<footer class="footer-distributed">

			<div class="footer-left">

				<div className='logo-name'>  <img src={AppIcon} style={{width:"5rem", height:"5rem"}} alt="" /> 
                <span>Snacko.health </span>
                </div>

				{/* <p class="footer-links">
					<a href="#" class="link-1">Home</a>
					
					<a href="#">Blog</a>
				
					<a href="#">Pricing</a>
				
					<a href="#">About</a>
					
					<a href="#">Faq</a>
					
					<a href="#">Contact</a>
				</p> */}

				<p class="footer-company-name">Sidkit Technologies Private Limited ©2024</p>
			</div>

			<div class="footer-center">

				{/* <div>
					<i class="fa fa-map-marker"></i>
					<p><span>444 S. Cedros Ave</span> Solana Beach, California</p>
				</div>

				<div>
					<i class="fa fa-phone"></i>
					<p>+1.555.555.5555</p>
				</div>

				<div>
					<i class="fa fa-envelope"></i>
					<p><a href="mailto:support@company.com">support@company.com</a></p>
				</div> */}

                    <p class="footer-links">
                      <div className="quiclinksfooter">
                        <div className="qlhead">
                            <p className='quicklinks-head'>Quick Links</p>
                        </div>
                        <div className="rightlrft">
                            <div className="qlleft">
                            <a href="#" class="link-1">Home</a>
					
					<a href="#">FAQ</a>
				
					<a href="#">Changelog</a>
                            </div>
                            <div className="qlright">
                            <a href="#">About</a>
					
					<a href="#">Terms of Service</a>
					
					<a href="#">Privacy Policy</a>
                            </div>
                            </div>
                            
                        </div>
                        
                        </p> 
					
				
					
				



			</div>

			<div class="footer-right">

				<p class="footer-company-about">
					<span className='contact-us-footr'>Contact Us</span>
					<p>Contact: +974568373989</p>
                    <p>Email: hello@snacko.health</p>
				</p>

				{/* <div class="footer-icons">

					<a href="#"><i class="fa fa-facebook"></i></a>
					<a href="#"><i class="fa fa-twitter"></i></a>
					<a href="#"><i class="fa fa-linkedin"></i></a>
					<a href="#"><i class="fa fa-github"></i></a>

				</div> */}

                <div className="appDownloadlink">
                    <a href="https://apps.apple.com/us/app/snacko-it/id6450678993"><img className='appstr' src={AppStoreimge} alt="snacko-app-store-icon" /></a>
                    <a href="https://play.google.com/store/apps/details?id=health.snacko"><img className='appstr' src={playstoreImg} alt="snackoplay-store-icon" /></a>
                </div>


                <iframe src="https://status.snacko.health/badge?theme=light&show=title,uptime,version,maintenance,incidents&width=250&height=30" 
                width="250" height="30" frameborder="0"
                 scrolling="no" style={{
                    colorScheme: 'light'
                 }}>
                </iframe>

			</div>

		</footer>
</>




      )
}

export default Footer