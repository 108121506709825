import React, { useState, useEffect, useCallback } from "react";
import "./recipes.css";
import { Search } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { useTheme } from "./context/ThemeContext";
import Footer from "./Footer";

// Debounce function to optimize API calls
const debounce = (func, delay) => {
  let timeout;
  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), delay);
  };
};

const Recipes = () => {

 const {theme} = useTheme();

  const navigate = useNavigate();

  const [recipes, setRecipes] = useState([]); // Stores all recipes
  const [searchResults, setSearchResults] = useState([]); // Stores filtered search results
  const [input, setInput] = useState(""); // User input
  const [visibleRecipes, setVisibleRecipes] = useState(30); // Number of recipes displayed

  // Fetch recipes only once
  useEffect(() => {
    const fetchRecipes = async () => {
      const res = await fetch("https://dummyjson.com/recipes");
      const data = await res.json();
      setRecipes(data.recipes || []);
    };

    fetchRecipes();
  }, []);

  // Debounced Search
  const handleSearch = useCallback(
    debounce((query) => {
      if (query.trim() === "") {
        setSearchResults([]);
      } else {
        setSearchResults(
          recipes.filter((recipe) =>
            recipe.name.toLowerCase().includes(query.toLowerCase())
          )
        );
      }
    }, 500),
    [recipes]
  );

  useEffect(() => {
    handleSearch(input);
  }, [input, handleSearch]);

  // Infinite Scroll: Load more recipes when scrolling down
  useEffect(() => {
    const handleScroll = () => {
      if (
        window.innerHeight + window.scrollY >= document.body.offsetHeight - 100
      ) {
        setVisibleRecipes((prev) => prev + 30);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    
    <div className={`homepage-container ${theme}`}>

    <div className="recipe-page">
      {/* Header */}
      <div className="upperSection">
        <div className="recipe-heading">
          <h1 className="Heading-recipe">Tasty Recipes at Your Fingertips</h1>
        </div>
        <div className="smalltext">
          <span>
            Explore a world of flavors with easy-to-follow recipes. Search, cook, and enjoy!
          </span>
        </div>
      </div>

      {/* Search Box */}
      <div className="search-container">
        <div className="search-box">
          <input
            className="search-input"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            placeholder="What are you looking for?"
            type="text"
          />
          <button className="search-icon">
            <Search size={26}  strokeWidth={2.5} />
          </button>
        </div>
      </div>

      {/* Show search results (Only names) */}
      {input.trim() !== "" && (
        <div className="search-results">
          {searchResults.length > 0 ? (
            searchResults.map((r) => (
              <span onClick={()=>{
                navigate(`/view-recipe/${r.id}`)
              }} key={r.id} className="recipe-item">
                {r.name}
              </span>
            ))
          ) : (
            <p className="no-results-message">No recipes found</p>
          )}
        </div>
      )}

      {/* Recipe Grid (Stays Visible Even While Searching) */}
      <div className="recipes-container">
        {recipes.slice(0, visibleRecipes).map((recipe) => (
          <div onClick={()=>{
            navigate(`/view-recipe/${recipe.id}`)
            console.log(recipe.id)}} key={recipe.id} className="recipe-card">

            <img src={recipe.image} alt={recipe.name} className="recipe-image" />
            <span className="recipe-title">{recipe.name}</span>
          </div>
        ))}
      </div>
     
    </div>
    <Footer />
    </div>
  );
};

export default Recipes;
