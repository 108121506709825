import React from "react";

import AppStore from './images/app_store.svg'
import PlayStore from './images/play_store.svg'
import Thali from './images/Thali.png'
import "./homepage.css";
import { useTheme } from "./context/ThemeContext";

function HomePage() {
  const {theme} = useTheme();
  return (


    <div className={`homepage-container ${theme}`}> {/* Apply theme class */}
    
    
    <div className="Homepage">
      <img src={Thali} alt="Your SVG" style={{ height: "35vh" }} />

      <div id="paraText">
        <center>
          <b style={{ fontSize: "36pt" }} className="homepage-title">Good Food, Good Life</b>
          <br />
          <span className="homepage-subtitle" style={{ fontSize: "18pt" }}>Nutritional information for free</span>
        </center>
      </div>

      <br />
      <center>
        <a href="mailto:ankit@snacko.health">
          <span className="testMsg">
            <u>Feedback</u>
          </span>
        </a>
        <br />
      <div className="play-app-btn">
        <a className="store-logos" href="https://apps.apple.com/us/app/snacko-it/id6450678993">
          <img src={AppStore} alt="App Store" className="store-logo" />
        </a>

        <span> </span>

        <a className="store-logos" href="https://play.google.com/store/apps/details?id=health.snacko">
          <img src={PlayStore} alt="Play Store" className="store-logo" />
        </a>
        </div>
      </center>

      {/* <div className="btn-homepage">
        <p></p>
      </div> */}
    </div>
  </div>

  );
}

export default HomePage;
