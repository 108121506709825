import React, { useState, useEffect } from "react";
import { useTheme } from "./context/ThemeContext"; // Import Theme Context
import { Sun, Moon, Menu, X } from "lucide-react"; // Icons from Lucide
import "./header.css"; // Import CSS
import appLogo from "./images/app_icon.png"

const Header = () => {
  const { theme, toggleTheme } = useTheme();
  const [isOpen, setIsOpen] = useState(false);

  // Ensure body class updates when theme changes
  useEffect(() => {
    document.body.className = theme; // Set the class on body
  }, [theme]);

  return (
    <header className="header">
      <div className="navbar">
        {/* Logo */}
        <a href="/"><img className="logo h-10 w-auto" src={appLogo} style={{width:"2.5rem", height:"2.5rem"}} alt="app_icon" /></a>

        {/* Desktop Menu */}
        <nav className="nav-links">
          <a href="/">Home</a>
          <a href="/recipe">Recipes</a>
          <a href="/blogs">Blog</a>
          {/* <a href="/mobile/changelog">Changelog</a>
          <a href="/privacy-policy">Privacy Policy</a> */}
        </nav>

        {/* Theme Toggle Button */}
        <button onClick={toggleTheme} className="theme-toggle">
          {theme === "light" ? (
            <Moon size={24} className="icon" />
          ) : (
            <Sun size={24} className="icon" />
          )}
        </button>

        {/* Mobile Menu Button */}
        <button className="menu-toggle" onClick={() => setIsOpen(!isOpen)}>
          {isOpen ? (
            <X size={28} className="icon" />
          ) : (
            <Menu size={28} className="icon" />
          )}
        </button>
      </div>

      {/* Mobile Menu */}
      {isOpen && (
        <nav className="mobile-menu">
          <a href="/" onClick={() => setIsOpen(false)}>Home</a>
          <a href="/recipe" onClick={() => setIsOpen(false)}>Recipes</a>
          <a href="/blogs" onClick={() => setIsOpen(false)}>Blog</a>
          <a href="/mobile/changelog" onClick={() => setIsOpen(false)}>Changelog</a>
          <a href="/privacy-policy" onClick={() => setIsOpen(false)}>Privacy Policy</a>
        </nav>
      )}
    </header>
  );
};

export default Header;
