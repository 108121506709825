import React, { useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import "./viewRecipe.css";
import { useTheme } from "./context/ThemeContext";
import AdSense from "./AdSense";
import Footer from "./Footer";

const ViewRecipe = () => {
const theme = useTheme();

  const { id } = useParams(); //this will fetch recipe id from url
  const [recipe, setRecipes] = useState(null);
  const [activeTab, setActiveTab] = useState("ingredients");





  const handleTabClick = (tabs) => {
    setActiveTab(tabs);
  };

  useEffect(() => {
    fetch(`https://dummyjson.com/recipes/${id}`)
      .then((res) => res.json())
      .then((data) => setRecipes(data))
      .catch((error) => console.log("error fetching data", error));
  }, [id]);

  if (!recipe) {
    return <center><h1>Loading recipe details...</h1></center> 
  }

  const sampleNutrition = {
    calories: recipe.caloriesPerServing,
    details: [
      { name: "Total Fat", value: "3g", percent: "4.6%" },
      { name: "Saturated Fat", value: "0.5g", percent: "2.5%" },
      { name: "Trans Fat", value: "0g",percent: "-" },
      { name: "Cholesterol", value: "80mg", percent: "27%" },
      { name: "Sodium", value: "50mg", percent: "2.1%" },
      { name: "Total Carbohydrate", value: "0g", percent: "0%" },
      { name: "Protein", value: "25g",percent: "-" },
      { name: "Vitamin C", value: "0mg", percent: "0%" },
      { name: "Iron", value: "0.36mg", percent: "2%" },
    ],
  };


  return (

<div className={`homepage-container ${theme}`}>
    
    <AdSense />

    <div className="recipe-details">
      <h1>{recipe.name}</h1>
      <h5>{recipe.author}</h5>
      <span>Cuisine: {recipe.cuisine}</span>
      <div className="recipe-image-container">
        <img
          className="view-recipe-image"
          src={recipe.image}
          alt={recipe.name}
        />
      </div>



      <div className="tabs-container">
                    <button 
                        className={`tab-btn ${activeTab === "ingredients" ? "active" : ""}`} 
                        onClick={() => setActiveTab("ingredients")}
                    >
                        Ingredients
                    </button>
                    <button 
                        className={`tab-btn ${activeTab === "nutrition" ? "active" : ""}`} 
                        onClick={() => setActiveTab("nutrition")}
                    >
                        Nutritional Chart
                    </button>
                    <button 
                        className={`tab-btn ${activeTab === "preparation" ? "active" : ""}`} 
                        onClick={() => setActiveTab("preparation")}
                    >
                        Preparation
                    </button>
                </div>


                <div className="tab-content">
                    {activeTab === "ingredients" && (
                        <div className="ingredients-data">
                            {recipe.ingredients.map((item, index) => (
                                <p key={index} className="ingredient-item">{item}</p>
                            ))}
                        </div>
                    )}

                    {activeTab === "nutrition" && (
                        <div className="nutrition-container">
                        {/* <h2 className="nutrition-title">Nutrition Facts</h2> */}
                        <p className="nutrition-serving">Amount per serving</p>
                        <h3 className="nutrition-calories">
                          Calories <span>{sampleNutrition.calories}</span>
                        </h3>
                        <hr />
                  
                        <table className="nutrition-table">
                          <tbody>
                            {sampleNutrition.details.map((item, index) => (
                              <tr key={index}>
                                <td className="nutrient-name">{item.name}</td>
                                <td className="nutrient-value">{item.value}</td>
                                {item.percent && <td className="nutrient-percent">{item.percent}</td>}
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    )}

                    {activeTab === "preparation" && (
                        <div className="preparation-data">
                           
                            <span> <strong>Meal Type:</strong> {recipe.mealType.join(", ") + "." }</span>
                            <h4>Preparation Steps:</h4>
                            <p>{recipe.instructions}</p>
                        </div>
                    )}
</div>
    </div>
    <Footer />
    </div>
   
  );
};

export default ViewRecipe;
